import providesTags from './helpers/providesTags';

export default function getAgilityAssessmentEndpoints(builder) {
  return {
    getAgilityQuestionnaire: builder.query({
      query: ({ surveyId, language = 'none' }) =>
        `/agility-assessment/${surveyId}/questionnaire/${language}`,
      providesTags: (result) =>
        providesTags({ type: 'AgilityAssessmentQuestionnaire', result }),
    }),
    checkAgilityQuestionnaire: builder.mutation({
      query: ({ surveyId, email }) => ({
        url: `/agility-assessment/${surveyId}/check`,
        method: 'POST',
        body: { email },
      }),
    }),
    submitAgilityQuestionnaire: builder.mutation({
      query: ({ surveyId, email, role, answers }) => ({
        url: `/agility-assessment/${surveyId}/submit`,
        method: 'POST',
        body: { email, role, answers },
      }),
    }),
    getAgilityAssessmentStatistics: builder.query({
      query: ({ companyId, ...params }) => ({
        url: `/companies/${companyId}/assessment/statistics`,
        method: 'GET',
        params,
      }),
      providesTags: (result) =>
        providesTags({ type: 'AgilityAssessmentStatistics', result }),
    }),
    getAgilityAssessmentAnalysis: builder.query({
      query: ({ companyId, ...params }) => ({
        url: `/companies/${companyId}/assessment/analysis`,
        method: 'GET',
        params,
      }),
      providesTags: (result) =>
        providesTags({ type: 'AgilityAssessmentAnalysis', result }),
    }),
    createAssessmentCampaign: builder.mutation({
      query: ({ companyId, campaign }) => ({
        url: `/agility-assessment/${companyId}/campaign`,
        method: 'POST',
        body: campaign,
      }),
      invalidatesTags: ['Company'],
    }),
    updateAssessmentCampaign: builder.mutation({
      query: ({ companyId, assessmentId, updates }) => ({
        url: `/agility-assessment/${companyId}/campaign/${assessmentId}`,
        method: 'PUT',
        body: updates,
      }),
      invalidatesTags: ['Company'],
    }),
    linkAssessmentCampaign: builder.mutation({
      query: ({ companyId, assessmentId, campaignName }) => ({
        url: `/agility-assessment/${companyId}/assessment-campaigns`,
        method: 'POST',
        body: {
          assessmentId,
          campaignName,
        },
      }),
      invalidatesTags: ['Company'],
    }),
    unlinkAssessmentCampaign: builder.mutation({
      query: ({ companyId, assessmentId }) => ({
        url: `/agility-assessment/${companyId}/assessment-campaigns`,
        method: 'DELETE',
        body: {
          assessmentId,
        },
      }),
      invalidatesTags: ['Company'],
    }),
    getAssessmentDimensions: builder.query({
      query: () => '/agility-assessment/dimensions',
    }),
    getAssessmentQuestionSets: builder.query({
      query: () => '/agility-assessment/question-sets',
    }),
    updateSurveyReflections: builder.mutation({
      query: ({ surveyId, reflections }) => ({
        url: `/agility-assessment/${surveyId}/reflections`,
        method: 'POST',
        body: { reflections },
      }),
      invalidatesTags: ['SessionAgileSurvey'],
    }),
    updateSurveyChatThread: builder.mutation({
      query: ({ surveyId, dimension, threadId }) => ({
        url: `/agility-assessment/${surveyId}/thread`,
        method: 'POST',
        body: { dimension, threadId },
      }),
      invalidatesTags: ['SessionAgileSurvey'],
    }),
    exportFreeTextResponses: builder.query({
      query: ({ companyId, assessmentId }) =>
        `/agility-assessment/${companyId}/campaign/${assessmentId}/free-text-responses`,
    }),
  };
}
