import React from 'react';
import { Link } from 'react-router-dom';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
} from '@mui/material';
import {
  Assessment as ReportIcon,
  Person as PeopleIcon,
  Dns as CourseIcon,
  Business as CompanyIcon,
  RateReview,
  LocalOffer,
  LibraryBooks,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
  selectCurrentUser,
  selectHasMultipleCompanies,
} from 'features/auth/slice';

export default function AdminListItems() {
  const { t } = useTranslation(['common']);

  const user = useSelector(selectCurrentUser);
  const hasMultipleCompanies = useSelector(selectHasMultipleCompanies);
  const isAppAdmin = user?.role === 'admin';
  const isManager = user?.role === 'manager';
  const isOrgAdmin = !!user?.organization?._id;

  if (!isAppAdmin && !isManager && !hasMultipleCompanies && !isOrgAdmin) {
    return <></>;
  }

  return (
    <List>
      <ListSubheader disableSticky>{t('common:nav.admins')}</ListSubheader>
      {isAppAdmin && (
        <>
          <ListItem button component={Link} to="/admin" data-cy="admin">
            <ListItemIcon>
              <ReportIcon />
            </ListItemIcon>
            <ListItemText primary={t('common:nav.adminDashboard')} />
          </ListItem>
          <ListItem button component={Link} to="/partners" data-cy="partners">
            <ListItemIcon>
              <PeopleIcon />
            </ListItemIcon>
            <ListItemText primary={t('common:nav.partners')} />
          </ListItem>
          <ListItem button component={Link} to="/courses" data-cy="courses">
            <ListItemIcon>
              <CourseIcon />
            </ListItemIcon>
            <ListItemText primary={t('common:nav.courses')} />
          </ListItem>
          <ListItem
            button
            component={Link}
            to="/surveyResponses"
            data-cy="surveyResponses"
          >
            <ListItemIcon>
              <RateReview />
            </ListItemIcon>
            <ListItemText primary={t('common:nav.surveyResponses')} />
          </ListItem>
          <ListItem button component={Link} to="/coupons" data-cy="coupons">
            <ListItemIcon>
              <LocalOffer />
            </ListItemIcon>
            <ListItemText primary={t('common:nav.coupons')} />
          </ListItem>
          <ListItem
            button
            component={Link}
            to="/admin/content-library"
            data-cy="admin-library"
          >
            <ListItemIcon>
              <LibraryBooks />
            </ListItemIcon>
            <ListItemText primary={t('common:nav.library')} />
          </ListItem>
        </>
      )}
      <ListItem button component={Link} to="/companies" data-cy="companies">
        <ListItemIcon>
          <CompanyIcon />
        </ListItemIcon>
        <ListItemText primary={t('common:nav.companies')} />
      </ListItem>
    </List>
  );
}
