import React, { useEffect, useLayoutEffect } from 'react';
import { Navigate, useLocation, Link as RouterLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Checkbox,
  Container,
  Fade,
  FormControlLabel,
  Grid,
  Hidden,
  InputAdornment,
  Link,
  TextField,
  Typography,
  Paper,
} from '@mui/material';
import PasswordIcon from '@mui/icons-material/Lock';
import EmailIcon from '@mui/icons-material/MailOutline';
import { styled } from '@mui/system';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useTranslation, Trans } from 'react-i18next';

import { useLoginMutation } from 'app/api';
import {
  setCredentials,
  setRememberedEmail,
  toggleRememberMe,
} from 'features/auth/slice';
import { actions as achievementsActions } from 'features/achievements/slice';

const RootContainer = styled(Container)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
  padding: 0,
  [theme.breakpoints.down('md')]: {
    alignItems: 'flex-start',
  },
}));

const LoginForm = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    width: '50%',
    padding: theme.spacing(10),
    paddingTop: theme.spacing(14),
  },
  [theme.breakpoints.down('md')]: {
    height: '95vh',
  },
  padding: theme.spacing(3),
  width: '100%',
  paddingBottom: theme.spacing(7),
}));

const LoginImageRoot = styled(Grid, {
  shouldForwardProp: (prop) => prop !== 'isKoc',
})(({ theme, isKoc }) => ({
  width: '50%',
  height: '640px',
  padding: theme.spacing(3),
  borderTopLeftRadius: 5,
  borderBottomLeftRadius: 5,
  backgroundColor: isKoc ? '#fff' : theme.palette.secondary.main,
  backgroundSize: isKoc ? 'contain' : 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: isKoc ? 'center center' : 'right bottom',
  backgroundImage: `url(${isKoc ? 'login-koc.jpg' : 'login.png'})`,
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'right',
}));

const LoginImageText = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'isKoc',
})(({ theme, isKoc }) => ({
  color: isKoc ? theme.palette.primary.main : '#fff',
  fontWeight: 300,
}));

const LoginImageTextStrong = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'isKoc',
})(({ theme, isKoc }) => ({
  color: isKoc ? theme.palette.primary.main : '#fff',
  fontWeight: 600,
  marginLeft: 'auto',
  paddingBottom: '.75rem',
  borderBottom: `1px solid ${isKoc ? theme.palette.primary.main : '#fff'}`,
  whiteSpace: 'nowrap',
}));

const LoginGridItem = styled(Grid)(({ theme }) => ({
  paddingBottom: theme.spacing(2),
}));

const HelpLink = styled(Link)(({ theme }) => ({
  fontSize: 14,
  fontFamily: theme.typography.fontFamily,
  color: theme.palette.secondary.main,
}));

const LoginLink = styled(Link)(({ theme }) => ({
  fontSize: 15,
  fontFamily: theme.typography.fontFamily,
}));

const AgreementText = styled(Typography)(({ theme }) => ({
  fontSize: 15,
  fontFamily: theme.typography.fontFamily,
}));

const schema = yup.object().shape({
  username: yup.string().email().required(),
  password: yup.string().required(),
});

const getRedirectPath = ({
  appRole,
  companyRole,
  companyId,
  teamId,
  organizationId,
}) => {
  switch (appRole) {
    case 'student':
      if (organizationId) {
        return `/organizations/${organizationId}`;
      } else if (companyRole === 'companyAdmin') {
        return `/companies/${companyId}`;
      } else if (companyRole === 'teamAdmin') {
        return `/companies/${companyId}/teams/${teamId}`;
      } else {
        return '/student';
      }
    case 'trainer':
    case 'assistant':
      return '/trainer';
    case 'admin':
      return '/admin';
    case 'manager':
      return '/companies';
    default:
      return '/student';
  }
};

export default function Login() {
  const { t } = useTranslation('auth');
  const location = useLocation();
  const { user, firstLogin, rememberMe, rememberedEmail } = useSelector(
    (state) => state.auth
  );
  const dispatch = useDispatch();
  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
    watch,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      username: rememberedEmail || '',
    },
  });
  const [login, { isLoading }] = useLoginMutation();
  const watchUsername = watch('username');
  const [isKoc, setIsKoc] = React.useState(false);

  useEffect(() => {
    if (rememberMe) {
      dispatch(setRememberedEmail(watchUsername));
    }
  }, [dispatch, rememberMe, watchUsername]);

  useLayoutEffect(() => {
    setIsKoc(/koc/i.test(window.location.hostname));
  }, []);

  const onSubmit = async (data) => {
    try {
      const payload = await login(data).unwrap();
      dispatch(setCredentials(payload));
    } catch (err) {
      if (err?.status === 401) {
        setError('username', { type: 'manual', message: ' ' });
        setError('password', {
          type: 'manual',
          message: 'incorrect username or password',
        });
      } else {
        setError('username', { type: 'manual', message: err?.data?.message });
      }
    }
  };

  if (user) {
    const companyId = user?.companies?.length && user.companies[0].company?._id;
    const teamId = user?.companies?.length && user.companies[0].team?._id;
    const companyRole = user?.companies?.length && user.companies[0].role;
    const organizationId = user?.organization?._id;

    const appRole = user?.role;

    const isPrivileged =
      ['admin', 'trainer', 'assistant', 'manager'].includes(user?.role) ||
      ['companyAdmin', 'teamAdmin'].includes(companyRole);

    const cameFrom = location?.state?.from;

    const redirectPath = getRedirectPath({
      appRole,
      companyRole,
      companyId,
      teamId,
      organizationId,
    });

    if (firstLogin) {
      return <Navigate to="/settings" replace />;
    }

    if (cameFrom) {
      if (
        isPrivileged &&
        (cameFrom === '/student' || cameFrom.pathname === '/student')
      ) {
        return <Navigate to={redirectPath} replace />;
      } else {
        let redirectTo = cameFrom.pathname;
        if (cameFrom.search) {
          redirectTo += cameFrom.search;
        }

        return <Navigate to={redirectTo} replace />;
      }
    } else {
      return <Navigate to={redirectPath} replace />;
    }
  } else {
    dispatch(achievementsActions.emptyCart());
  }

  const aep = 'Agile Education';

  return (
    <RootContainer disableGutters>
      <Fade in timeout={1000}>
        <Paper elevation={4}>
          <Grid container>
            <Hidden mdDown>
              <LoginImageRoot item isKoc={isKoc}>
                <Trans i18nKey="auth:welcome">
                  <LoginImageText variant="h4" isKoc={isKoc}>
                    Welcome to
                  </LoginImageText>
                  <LoginImageText variant="h4" isKoc={isKoc}>
                    The {{ aep }}
                  </LoginImageText>
                  <LoginImageTextStrong variant="h3" isKoc={isKoc}>
                    ScrumLab
                  </LoginImageTextStrong>
                </Trans>
              </LoginImageRoot>
            </Hidden>
            <LoginForm container>
              <Grid container>
                <Grid item xs>
                  <Typography variant="subtitle1">
                    {t('welcomeMessage')}
                  </Typography>
                </Grid>
                <Grid item>
                  <HelpLink
                    component="a"
                    target="_blank"
                    href="mailto:online-learning@scruminc.com?subject=Issue accessing ScrumLab"
                    paragraph
                  >
                    {t('needHelp')}
                  </HelpLink>
                </Grid>
              </Grid>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Controller
                  name="username"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      variant="outlined"
                      size="medium"
                      placeholder={t('email')}
                      label={t('email')}
                      InputProps={{
                        inputProps: {
                          'data-cy': 'email',
                        },
                        style: {
                          backgroundColor: '#fff',
                        },
                        startAdornment: (
                          <InputAdornment position="start">
                            <EmailIcon />
                          </InputAdornment>
                        ),
                      }}
                      error={errors?.username}
                      helperText={errors?.username?.message || ' '}
                    />
                  )}
                />
                <Controller
                  name="password"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      variant="outlined"
                      size="medium"
                      placeholder={t('password')}
                      label={t('password')}
                      type="password"
                      InputProps={{
                        inputProps: {
                          'data-cy': 'password',
                        },
                        style: { backgroundColor: '#fff' },
                        startAdornment: (
                          <InputAdornment position="start">
                            <PasswordIcon />
                          </InputAdornment>
                        ),
                      }}
                      error={errors?.password}
                      helperText={errors?.password?.message || ' '}
                    />
                  )}
                />
                <Grid container>
                  <LoginGridItem item container xs={12}>
                    <Grid item xs>
                      <FormControlLabel
                        control={
                          <Checkbox
                            id="rememberMe-checkbox"
                            checked={rememberMe}
                            value={String(rememberMe)}
                            onChange={(e) =>
                              dispatch(toggleRememberMe(e.target.checked))
                            }
                            color="secondary"
                            inputProps={{
                              'data-cy': 'rememberMe-checkbox',
                            }}
                          />
                        }
                        label={t('rememberMe')}
                      />
                    </Grid>
                    <Grid item alignItems="flex-end">
                      <Button
                        type="submit"
                        variant="contained"
                        color="secondary"
                        disabled={isLoading}
                        size="large"
                      >
                        {t('login')}
                      </Button>
                    </Grid>
                  </LoginGridItem>
                  <LoginGridItem
                    item
                    container
                    xs={12}
                    justifyContent="space-between"
                  >
                    <Grid item xs>
                      <LoginLink
                        id="self_signup_link"
                        component={RouterLink}
                        to="/self-signup"
                        paragraph
                        color="secondary"
                        data-cy="self-signup-link"
                      >
                        <Trans i18nKey="auth:register">
                          Need an account?{' '}
                          <span data-cy="self-signup-link">Register here.</span>
                        </Trans>
                      </LoginLink>
                    </Grid>
                    <Grid item alignItems="flex-end">
                      <LoginLink
                        component={RouterLink}
                        to="/reset"
                        paragraph
                        color="secondary"
                      >
                        {t('forgotPassword')}
                      </LoginLink>
                    </Grid>
                  </LoginGridItem>
                  <LoginGridItem item xs style={{ paddingTop: '5rem' }}>
                    <AgreementText>
                      <Trans i18nKey="auth:agreement">
                        By signing in to ScrumLab, you agree to our{' '}
                        <Link
                          href="https://www.scruminc.com/terms-of-use/"
                          target="_blank"
                          component="a"
                          color="secondary"
                        >
                          Terms of Service
                        </Link>{' '}
                        and{' '}
                        <Link
                          href="https://www.scruminc.com/privacy-policy/"
                          target="_blank"
                          color="secondary"
                          component="a"
                        >
                          Privacy Policy
                        </Link>
                        .
                      </Trans>
                    </AgreementText>
                  </LoginGridItem>
                </Grid>
              </form>
            </LoginForm>
          </Grid>
        </Paper>
      </Fade>
    </RootContainer>
  );
}
