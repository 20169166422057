import React, { useEffect, lazy, Suspense, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { Box, Container } from '@mui/material';
import { styled } from '@mui/system';

import { AppBarContext } from './navContext';
import Loading from 'components/Loading';
import Login from 'features/auth/Login';
import PrivateRoute from 'router/PrivateRoute';
import importRetry from 'utils/importRetry';
import ScrollToTop from './ScrollToTop';
import Header from 'features/common/Header';
import Footer from 'components/Footer';
import PurchaseRSASF from 'features/courses/purchase/PurchaseRS@SF';

const AchievementRenewDialog = lazy(() =>
  import('features/achievements/renew/Dialog')
);
const ChangeLog = lazy(() => import('features/changelog/changelog'));
const StudentDashboard = lazy(() =>
  importRetry(() => import('features/student/Dashboard'))
);
const TrainerDashboard = lazy(() =>
  importRetry(() => import('features/trainers/Dashboard'))
);
const Courses = lazy(() =>
  importRetry(() => import('features/courses/Courses'))
);
const EditCourse = lazy(() =>
  importRetry(() => import('features/courses/EditCourse'))
);
const EditQuizModule = lazy(() =>
  importRetry(() =>
    import('features/courses/EditModule/QuizModule/EditQuizModule')
  )
);
const EditSurveyModule = lazy(() =>
  importRetry(() =>
    import('features/courses/EditModule/SurveyModule/EditSurveyModule')
  )
);
const Sessions = lazy(() =>
  importRetry(() => import('features/sessions/Sessions'))
);
const Session = lazy(() =>
  importRetry(() => import('features/sessions/Session'))
);
const Groups = lazy(() => importRetry(() => import('features/groups')));
const Group = lazy(() => importRetry(() => import('features/groups/group')));
const Users = lazy(() => importRetry(() => import('features/users/Users')));
const User = lazy(() => importRetry(() => import('features/users/User')));
const SelfSignup = lazy(() =>
  importRetry(() => import('features/auth/SelfSignUp'))
);
const ViewCourse = lazy(() =>
  importRetry(() => import('features/courses/ViewCourse'))
);
const OnDemandModule = lazy(() =>
  importRetry(() => import('features/courses/ViewModule'))
);
const ViewSession = lazy(() =>
  importRetry(() => import('features/sessions/view/ViewSession'))
);
const RequestResetToken = lazy(() =>
  importRetry(() => import('features/auth/RequestResetToken'))
);
const ResetPassword = lazy(() =>
  importRetry(() => import('features/auth/ResetPassword'))
);
const SetPassword = lazy(() =>
  importRetry(() => import('features/auth/SetPassword'))
);
const UserSettings = lazy(() =>
  importRetry(() => import('pages/UserSettings'))
);
const EditFileModule = lazy(() =>
  importRetry(() =>
    import('features/courses/EditModule/FileModule/EditFileModule')
  )
);
const EditVideoModule = lazy(() =>
  importRetry(() =>
    import('features/courses/EditModule/VideoModule/EditVideoModule')
  )
);
const EditHTMLModule = lazy(() =>
  importRetry(() =>
    import('features/courses/EditModule/HTMLModule/EditHTMLModule')
  )
);
const TypeformModule = lazy(() =>
  importRetry(() => import('features/courses/ViewModule/TypeformModule'))
);
const AdminViewResults = lazy(() =>
  importRetry(() =>
    import('features/courses/EditModule/QuizModule/AdminViewResults')
  )
);
const EditQuestion = lazy(() =>
  importRetry(() =>
    import('features/courses/EditModule/QuizModule/EditQuestion')
  )
);
const EditQuestionGroup = lazy(() =>
  importRetry(() =>
    import('features/courses/EditModule/QuizModule/EditQuestionGroup')
  )
);
const FindClasses = lazy(() => importRetry(() => import('pages/FindClasses')));
const Partners = lazy(() =>
  importRetry(() => import('features/partners/Partners'))
);
const ExamAnalysis = lazy(() =>
  importRetry(() => import('features/examAnalysis/Results'))
);
const AdminDashboard = lazy(() =>
  importRetry(() => import('features/admin/Dashboard'))
);
const CredentialLookup = lazy(() =>
  importRetry(() => import('features/credentialLookup'))
);
const BillingDashboard = lazy(() =>
  importRetry(() => import('features/billing/BillingDashboard'))
);
const InvoicePage = lazy(() =>
  importRetry(() => import('features/billing/InvoicePage'))
);
const SuccessfulPaymentPage = lazy(() =>
  importRetry(() => import('features/billing/SuccessfulPayment'))
);
const Trainer = lazy(() =>
  importRetry(() => import('features/trainers/Trainer'))
);
const EditInvoice = lazy(() =>
  importRetry(() => import('features/billing/EditInvoicePage'))
);
const Partner = lazy(() =>
  importRetry(() => import('features/partners/Partner'))
);
const FAQPage = lazy(() => importRetry(() => import('pages/FAQ/FAQ')));
const Feedback = lazy(() => importRetry(() => import('features/feedback')));
const PartnersDirectory = lazy(() =>
  importRetry(() => import('features/partners/Directory'))
);
const PokerDashboard = lazy(() =>
  importRetry(() => import('features/poker/Dashboard'))
);
const JoinPokerRoom = lazy(() =>
  importRetry(() => import('features/poker/JoinRoom'))
);
const PokerRoom = lazy(() => importRetry(() => import('features/poker/Room')));
const CompaniesList = lazy(() =>
  importRetry(() => import('features/companies/List'))
);
const OrganizationDashboard = lazy(() =>
  importRetry(() => import('features/organizations/Dashboard'))
);
const CompanyDashboard = lazy(() =>
  importRetry(() => import('features/companies/Dashboard'))
);
const TeamDashboard = lazy(() =>
  importRetry(() => import('features/teams/Dashboard'))
);
const SurveyResponsesDashboard = lazy(() =>
  importRetry(() => import('features/surveyResponses/Dashboard'))
);
const PurchaseRSTM = lazy(() =>
  importRetry(() => import('features/courses/purchase/PurchaseRSTM'))
);
const CouponDashboard = lazy(() =>
  importRetry(() => import('features/coupons/Dashboard'))
);
const AgilitySurvey = lazy(() =>
  importRetry(() => import('features/agilityAssessment/survey/Survey'))
);
const ContentLibrary = lazy(() =>
  importRetry(() => import('features/contentLibrary/ContentLibrary'))
);
const EditContentLibrary = lazy(() =>
  importRetry(() => import('features/contentLibrary/EditContentLibrary'))
);
const ViewContentLibraryModule = lazy(() =>
  importRetry(() =>
    import('features/content/ContentLibrary/ViewContentLibraryModule')
  )
);
const StudentPlaylist = lazy(() =>
  importRetry(() => import('features/student/StudentPlaylist'))
);
const ViewPlaylistModule = lazy(() =>
  importRetry(() =>
    import('features/content/ContentLibrary/ViewPlaylistModule')
  )
);
const ClientOverview = lazy(() =>
  importRetry(() => import('features/clients/Overview'))
);
const AIDialog = lazy(() => import('features/ai/AICoach/AIDialog'));
const SessionSurveyResults = lazy(() =>
  import('features/sessions/view/SessionSurveyResults')
);

const StyledContainer = styled(Container)(({ theme, appbarheight = 0 }) => ({
  flexGrow: 1,
  backgroundColor: theme.palette.secondary.lightGray,
  paddingTop: parseInt(theme.spacing(2), 10) + appbarheight,
  minHeight: window.self === window.top ? `calc(100vh - 38px)` : 0,
  '&.showDrawer': {
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - 240px)`,
      marginLeft: 240,
    },
  },
  [theme.breakpoints.down('sm')]: {
    margin: 0,
    paddingTop: appbarheight,
    paddingLeft: 0,
    paddingRight: 0,
  },
}));

export default function Router() {
  const location = useLocation();
  const { user } = useSelector((state) => state.auth);
  const [headerHeight, setHeaderHeight] = useState(50);
  const headerRef = useRef(null);

  // Create a resize observer to respond to header height changes
  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        const { height } = entry.contentRect;

        setHeaderHeight(height);
      }
    });

    if (headerRef?.current) {
      resizeObserver.observe(headerRef?.current);
    }

    return () => resizeObserver.disconnect();
  });

  useEffect(() => {
    Sentry.setTag('location', location.pathname);
  }, [location]);

  const isIFrame = window.self !== window.top;

  const SuspenseFallback = () => (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Loading />
    </Box>
  );

  return (
    <AppBarContext.Provider value={headerHeight || 50}>
      <ScrollToTop>
        <div style={{ display: 'flex' }}>
          {user && !isIFrame && <Header ref={headerRef} />}
          <StyledContainer maxWidth={false} appbarheight={headerHeight}>
            <Suspense fallback={<SuspenseFallback />}>
              <Routes>
                <Route path="/login" element={<Login />} />
                <Route
                  path="/billing/:id"
                  element={<SuccessfulPaymentPage />}
                />
                <Route
                  path="/provider-lookup"
                  element={<PartnersDirectory />}
                />
                <Route
                  path="/credential-lookup"
                  element={<CredentialLookup />}
                />
                <Route path="/self-signup" element={<SelfSignup />} />
                <Route path="/self-signup/:id" element={<SelfSignup />} />
                <Route
                  path="/frequently-asked-questions"
                  element={<FAQPage />}
                />
                <Route path="/reset" element={<RequestResetToken />} />
                <Route path="/reset/:token" element={<ResetPassword />} />
                <Route path="/set-password/:token" element={<SetPassword />} />
                <Route path="/find-class" element={<FindClasses />} />
                <Route path="/invoice/:id" element={<InvoicePage />} />
                <Route path="/changeLog" element={<ChangeLog />} />
                <Route
                  path="/admin"
                  element={
                    <PrivateRoute roles={['admin']}>
                      <AdminDashboard />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/courses"
                  element={
                    <PrivateRoute>
                      <Courses />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/courses/:search"
                  element={
                    <PrivateRoute>
                      <Courses />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/course/purchase/RSTM"
                  element={<PurchaseRSTM />}
                />
                <Route
                  path="/course/purchase/RSASF"
                  element={<PurchaseRSASF />}
                />
                <Route
                  path="/course/:id"
                  element={
                    <PrivateRoute roles={['admin']}>
                      <EditCourse />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/partners"
                  element={
                    <PrivateRoute roles={['admin']}>
                      <Partners />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/partner/:id"
                  element={
                    <PrivateRoute roles={['admin']}>
                      <Partner />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/coupons"
                  element={
                    <PrivateRoute roles={['admin']}>
                      <CouponDashboard />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/course/:courseId/QuizModule/:id"
                  element={
                    <PrivateRoute roles={['admin']}>
                      <EditQuizModule />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/module/QuizModule/:id"
                  element={
                    <PrivateRoute roles={['admin']}>
                      <EditQuizModule />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/course/:courseId/SurveyModule/:moduleId"
                  element={
                    <PrivateRoute>
                      <EditSurveyModule />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/course/:courseId/FileModule/:moduleId"
                  element={
                    <PrivateRoute
                      roles={['admin', 'trainer', 'assistant', 'manager']}
                    >
                      <EditFileModule />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/module/FileModule/:moduleId"
                  element={
                    <PrivateRoute
                      roles={['admin', 'trainer', 'assistant', 'manager']}
                    >
                      <EditFileModule />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/course/:courseId/VideoModule/:id"
                  element={
                    <PrivateRoute
                      roles={['admin', 'trainer', 'assistant', 'manager']}
                    >
                      <EditVideoModule />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/module/VideoModule/:id"
                  element={
                    <PrivateRoute
                      roles={['admin', 'trainer', 'assistant', 'manager']}
                    >
                      <EditVideoModule />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/course/:courseId/HTMLModule/:id"
                  element={
                    <PrivateRoute roles={['admin']}>
                      <EditHTMLModule />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/module/HTMLModule/:id"
                  element={
                    <PrivateRoute roles={['admin']}>
                      <EditHTMLModule />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/course/:courseId/QuizModule/:quizModuleId/question/:questionId/:locale"
                  element={
                    <PrivateRoute roles={['admin']}>
                      <EditQuestion />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/course/:courseId/QuizModule/:quizModuleId/group/:groupId/:locale"
                  element={
                    <PrivateRoute roles={['admin']}>
                      <EditQuestionGroup />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/exam-analysis"
                  element={
                    <PrivateRoute roles={['admin', 'trainer', 'manager']}>
                      <ExamAnalysis />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/trainer/:id"
                  element={
                    <PrivateRoute roles={['admin', 'manager']}>
                      <Trainer />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/view/session/:sessionId"
                  element={
                    <PrivateRoute>
                      <ViewSession />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/view/session/:sessionId/survey/:userId?"
                  element={
                    <PrivateRoute>
                      <SessionSurveyResults />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/view/playlist/:playlistId"
                  element={
                    <PrivateRoute>
                      <StudentPlaylist />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/view/QuizModule/results/:quiz_id/:session"
                  element={
                    <PrivateRoute
                      roles={['admin', 'trainer', 'assistant', 'manager']}
                    >
                      <AdminViewResults />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/view/TypeformModule/:moduleId/:sessionId/:typeformId"
                  element={
                    <PrivateRoute>
                      <TypeformModule />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/view/achievements/:achievementName"
                  element={
                    <Navigate
                      to="/student/achievements/:achievementName"
                      replace
                    />
                  }
                />
                <Route
                  path="/view/achievements"
                  element={<Navigate to="/student/achievements" replace />}
                />
                <Route
                  path="/view/course/:courseId"
                  element={
                    <PrivateRoute>
                      <ViewCourse />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/view/:moduleType/:moduleId/1"
                  element={
                    <Navigate to="/view/:moduleType/:moduleId" replace />
                  }
                />
                <Route
                  path="/view/course/:courseId/:moduleId"
                  element={
                    <PrivateRoute>
                      <OnDemandModule />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/view/:moduleType/:moduleId/:sessionId?"
                  element={
                    <PrivateRoute>
                      <OnDemandModule />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/content-library/:moduleId/:categoryId"
                  element={
                    <PrivateRoute>
                      <ViewContentLibraryModule />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/playlist/:playlistId/:moduleId/:categoryId"
                  element={
                    <PrivateRoute>
                      <ViewPlaylistModule />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/sessions"
                  element={
                    <PrivateRoute
                      roles={['admin', 'trainer', 'assistant', 'manager']}
                    >
                      <Sessions />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/sessions/:search"
                  element={
                    <PrivateRoute
                      roles={['admin', 'trainer', 'assistant', 'manager']}
                    >
                      <Sessions />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/session/:id"
                  element={
                    <PrivateRoute
                      roles={[
                        'admin',
                        'trainer',
                        'assistant',
                        'companyAdmin',
                        'consultant',
                        'orgAdmin',
                        'manager',
                      ]}
                    >
                      <Session />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/groups"
                  element={
                    <PrivateRoute
                      roles={['admin', 'trainer', 'assistant', 'manager']}
                    >
                      <Groups />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/group/:id"
                  element={
                    <PrivateRoute
                      roles={['admin', 'trainer', 'assistant', 'manager']}
                    >
                      <Group />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/users"
                  element={
                    <PrivateRoute
                      roles={['admin', 'trainer', 'assistant', 'manager']}
                    >
                      <Users />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/users/:searchTerms"
                  element={
                    <PrivateRoute
                      roles={['admin', 'trainer', 'assistant', 'manager']}
                    >
                      <Users />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/user/:id"
                  element={
                    <PrivateRoute
                      roles={[
                        'admin',
                        'trainer',
                        'assistant',
                        'orgAdmin',
                        'companyAdmin',
                        'consultant',
                        'teamAdmin',
                        'manager',
                      ]}
                    >
                      <User />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/settings"
                  element={
                    <PrivateRoute>
                      <UserSettings />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/billing"
                  element={
                    <PrivateRoute
                      roles={['admin', 'trainer', 'assistant', 'manager']}
                    >
                      <BillingDashboard />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/billing/:id"
                  element={
                    <PrivateRoute
                      roles={['admin', 'trainer', 'assistant', 'manager']}
                    >
                      <BillingDashboard />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/edit-invoice/:id"
                  element={
                    <PrivateRoute roles={['admin']}>
                      <EditInvoice />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/my-feedback"
                  element={
                    <PrivateRoute roles={['admin', 'trainer', 'manager']}>
                      <Feedback />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/companies"
                  element={
                    <PrivateRoute
                      roles={[
                        'admin',
                        'orgAdmin',
                        'companyAdmin',
                        'consultant',
                        'manager',
                      ]}
                    >
                      <CompaniesList />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/organizations/:organizationId"
                  element={
                    <PrivateRoute roles={['admin', 'orgAdmin']}>
                      <OrganizationDashboard />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/organizations/:organizationId/exams"
                  element={
                    <PrivateRoute roles={['admin', 'orgAdmin']}>
                      <ExamAnalysis />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/companies/:companyId"
                  element={
                    <PrivateRoute
                      roles={[
                        'admin',
                        'orgAdmin',
                        'companyAdmin',
                        'consultant',
                        'manager',
                      ]}
                    >
                      <CompanyDashboard />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/companies/:companyId/exams"
                  element={
                    <PrivateRoute
                      roles={[
                        'admin',
                        'orgAdmin',
                        'companyAdmin',
                        'consultant',
                        'manager',
                      ]}
                    >
                      <ExamAnalysis />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/companies/:companyId/teams/:teamId"
                  element={
                    <PrivateRoute
                      roles={[
                        'admin',
                        'orgAdmin',
                        'companyAdmin',
                        'consultant',
                        'teamAdmin',
                        'manager',
                      ]}
                    >
                      <TeamDashboard />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/companies/:companyId/teams/:teamId/exams"
                  element={
                    <PrivateRoute
                      roles={[
                        'admin',
                        'orgAdmin',
                        'companyAdmin',
                        'consultant',
                        'teamAdmin',
                        'manager',
                      ]}
                    >
                      <ExamAnalysis />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/trainer"
                  element={
                    <PrivateRoute
                      roles={['admin', 'trainer', 'assistant', 'manager']}
                    >
                      <TrainerDashboard />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/student/achievements/:achievementName"
                  element={
                    <PrivateRoute>
                      <StudentDashboard />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/student/my-activities/:inviteCode?"
                  element={
                    <PrivateRoute
                      roles={[
                        'admin',
                        'trainer',
                        'assistant',
                        'student',
                        'manager',
                      ]}
                    >
                      <StudentDashboard />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/student/renew/:achievementId"
                  element={
                    <PrivateRoute
                      roles={[
                        'admin',
                        'trainer',
                        'assistant',
                        'student',
                        'manager',
                      ]}
                    >
                      <StudentDashboard />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/student/*"
                  element={
                    <PrivateRoute
                      roles={[
                        'admin',
                        'trainer',
                        'assistant',
                        'student',
                        'manager',
                      ]}
                    >
                      <StudentDashboard />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/poker"
                  element={
                    <PrivateRoute>
                      <PokerDashboard />
                    </PrivateRoute>
                  }
                />
                <Route path="/poker/join" element={<JoinPokerRoom />} />
                <Route path="/poker/room/:code" element={<PokerRoom />} />
                <Route
                  path="/surveyResponses"
                  element={
                    <PrivateRoute roles={['admin']}>
                      <SurveyResponsesDashboard />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/organizations/:organizationId/surveyResponses"
                  element={
                    <PrivateRoute roles={['admin', 'orgAdmin']}>
                      <SurveyResponsesDashboard />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/companies/:companyId/surveyResponses"
                  element={
                    <PrivateRoute
                      roles={[
                        'admin',
                        'orgAdmin',
                        'companyAdmin',
                        'consultant',
                        'manager',
                      ]}
                    >
                      <SurveyResponsesDashboard />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/companies/:companyId/teams/:teamId/surveyResponses"
                  element={
                    <PrivateRoute
                      roles={[
                        'admin',
                        'orgAdmin',
                        'companyAdmin',
                        'consultant',
                        'teamAdmin',
                        'manager',
                      ]}
                    >
                      <SurveyResponsesDashboard />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/agility-assessment/survey/:surveyId"
                  element={<AgilitySurvey />}
                />
                <Route path="/content-library" element={<ContentLibrary />} />
                <Route
                  path="/admin/content-library"
                  element={
                    <PrivateRoute roles={['admin']}>
                      <EditContentLibrary />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/clients/:companyId/overview"
                  element={
                    <PrivateRoute
                      featureFlag="clientDashboardEnabled"
                      roles={['admin', 'orgAdmin', 'companyAdmin']}
                    >
                      <ClientOverview />
                    </PrivateRoute>
                  }
                />
                <Route path="*" element={<Navigate to="/student" replace />} />
              </Routes>
            </Suspense>
          </StyledContainer>
        </div>
        {user && !isIFrame && (
          <>
            <AchievementRenewDialog />
            <AIDialog />
            <Footer />
          </>
        )}
      </ScrollToTop>
    </AppBarContext.Provider>
  );
}
