import React, { useEffect, useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import {
  AppBar,
  Box,
  Button,
  Grid,
  Hidden,
  IconButton,
  MenuItem,
  Menu,
  Toolbar,
  Typography,
  Avatar,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { styled } from '@mui/system';
import {
  AccountCircle,
  ArrowDropDown as ArrowDropDownIcon,
  Menu as MenuIcon,
  MenuOpen as MenuOpenIcon,
} from '@mui/icons-material';
import { toast } from 'react-toastify';
import classNames from 'classnames';

import { AppBarContext } from 'router/navContext';
import { useUpdateUserLanguageMutation } from 'app/api';
import { logoutUser, selectCurrentUser } from 'features/auth/slice';
import { interfaceLanguages } from 'utils/languages';
import ShoppingCartButton from 'features/common/ShoppingCartButton';
import AICoachButton from 'features/ai/AICoach/AICoachButton';
import UserDrawer from './UserDrawer';
import AuthBanner from 'components/AuthBanner';
import RenewalExamBanner from 'features/common/banners/RenewalExamBanner';
import { useListRenewalExams } from 'features/achievements/hooks/useListRenewalExams';
import logo from './logo.png';
import { useTranslation } from 'react-i18next';

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
}));

const HeaderContent = styled(Grid)(({ theme }) => ({
  height: 50,
  padding: theme.spacing(0, 1),
}));

const MenuButton = styled(IconButton)(({ theme }) => ({
  '&.hidden': {
    opacity: 0,
  },
}));

const Logo = styled('img')(({ theme }) => ({
  width: 'auto',
  objectFit: 'contain',
  height: 38,
  [theme.breakpoints.up('sm')]: {
    height: 48,
  },
  paddingRight: theme.spacing(2),
}));

const Grow = styled(Typography)({
  flexGrow: 1,
});

const AvatarIcon = styled(AccountCircle)(({ theme }) => ({
  width: 25,
  height: 25,
}));

function Header(_props, ref) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const location = useLocation();

  const dispatch = useDispatch();
  const user = useSelector(selectCurrentUser);
  const [updateUserLanguage] = useUpdateUserLanguageMutation();
  const appBarHeight = useContext(AppBarContext);

  const [navMenuOpen, setNavMenuOpen] = useState(false);
  const [userMenuAnchor, setUserMenuAnchor] = useState(null);
  const [languageMenuAnchor, setLanguageMenuAnchor] = useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const { unlockedCount } = useListRenewalExams();

  const { t } = useTranslation('common');

  const hasPendingRenewalExams = unlockedCount > 0;
  const hideMenuButton =
    location?.pathname === '/student' && user.role === 'student' && !isMobile;
  const showRenewalBanner = hasPendingRenewalExams && user?.role !== 'admin';

  // handlers for controlling menus
  const handleClickNavMenu = () => setNavMenuOpen((prev) => !prev);
  const handleCloseNavMenu = () => setNavMenuOpen(false);
  const handleClickUserMenu = (event) => setUserMenuAnchor(event.currentTarget);
  const handleCloseUserMenu = () => setUserMenuAnchor(null);
  const handleClickLanguageMenu = (event) =>
    setLanguageMenuAnchor(event.currentTarget);
  const handleCloseLanguageMenu = () => setLanguageMenuAnchor(null);

  // handlers for controlling actions
  const handleChangeLanguage = async ({ value, label }) => {
    try {
      setLanguageMenuAnchor(null);
      setSelectedLanguage(label);
      await updateUserLanguage({
        ...user,
        language: value,
      });
    } catch (err) {
      toast.error(err?.message || 'Something went wrong');
    }
  };
  const handleLogout = () => {
    handleCloseUserMenu();
    dispatch(logoutUser());
  };

  // sets the selected language when the user loads or changes
  useEffect(() => {
    const selectedLanguage = interfaceLanguages.find(
      (c) => c.value === user?.language
    )?.label;
    setSelectedLanguage(selectedLanguage);
  }, [user]);

  return (
    <>
      <StyledAppBar ref={ref} position="fixed">
        <Toolbar variant="dense" disableGutters>
          <Grid container direction="column">
            <Grid item>
              <AuthBanner />
            </Grid>
            <HeaderContent item container alignItems="center">
              <Grid item>
                <MenuButton
                  color="inherit"
                  aria-label="Menu"
                  onClick={handleClickNavMenu}
                  className={classNames({ hidden: hideMenuButton })}
                  data-cy="menu-button"
                  disabled={hideMenuButton}
                >
                  {navMenuOpen ? <MenuOpenIcon /> : <MenuIcon />}
                </MenuButton>
              </Grid>
              <Grid item>
                <Logo
                  sx={{ display: { xs: 'none', sm: 'block' } }}
                  src={logo}
                  alt="Scrum Inc. Logo"
                />
              </Grid>
              <Grid item xs>
                <Grow variant="h6" color="inherit" noWrap>
                  <Box
                    sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}
                  >
                    {'Welcome to ScrumLab'}
                    {`, ${user.first_name}`}
                  </Box>
                </Grow>
              </Grid>
              <Grid item>
                <AICoachButton />
                <Hidden mdDown>
                  <ShoppingCartButton />
                </Hidden>
                <Button
                  aria-controls="language-menu"
                  aria-haspopup={true}
                  onClick={handleClickLanguageMenu}
                  style={{ color: 'white' }}
                >
                  {selectedLanguage}
                  <ArrowDropDownIcon />
                </Button>
                <Menu
                  id="language-menu"
                  anchorEl={languageMenuAnchor}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(languageMenuAnchor)}
                  onClose={handleCloseLanguageMenu}
                >
                  {interfaceLanguages.map((c) => (
                    <MenuItem
                      key={c.value}
                      value={c.value}
                      onClick={() => handleChangeLanguage(c)}
                    >
                      {c.label}
                    </MenuItem>
                  ))}
                </Menu>
                <IconButton
                  aria-controls="user-menu"
                  aria-haspopup="true"
                  onClick={handleClickUserMenu}
                  color="inherit"
                  size="large"
                >
                  {user?.image ? (
                    <Avatar
                      alt={user?.full_name}
                      src={user?.image}
                      style={{ width: 25, height: 25 }}
                    />
                  ) : (
                    <AvatarIcon />
                  )}
                </IconButton>
                <Menu
                  id="user-menu"
                  anchorEl={userMenuAnchor}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(userMenuAnchor)}
                  onClose={handleCloseUserMenu}
                >
                  <MenuItem onClick={handleLogout}>{t('logout')}</MenuItem>
                  <MenuItem
                    component={Link}
                    to="/frequently-asked-questions"
                    onClick={handleCloseUserMenu}
                  >
                    {t('help')}
                  </MenuItem>
                  <MenuItem
                    component={Link}
                    to="/settings"
                    onClick={handleCloseUserMenu}
                  >
                    {t('settings')}
                  </MenuItem>
                </Menu>
              </Grid>
            </HeaderContent>
            {showRenewalBanner && (
              <Grid item>
                <RenewalExamBanner role={user?.role} />
              </Grid>
            )}
          </Grid>
        </Toolbar>
      </StyledAppBar>
      <UserDrawer
        open={navMenuOpen}
        onClose={handleCloseNavMenu}
        appBarHeight={appBarHeight}
      />
    </>
  );
}

export default React.forwardRef(Header);
