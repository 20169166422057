import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  joyride: {
    stepIndex: 0,
    run: false,
  },
  activeTab: 'students',
  achievementStatus: null,
  achievementType: null,
  achievementTypes: [],
  search: '',
  order: 'asc',
  orderBy: 'user.full_name',
  page: 0,
  limit: 25,
  addTeamDialogOpen: false,
  addCourseDialogOpen: false,
  deleteTeamDialog: {
    open: false,
    teamId: null,
    teamName: '',
  },
  editCourseSeatsDialog: {
    open: false,
    course: null,
    teamId: null,
    totalSeats: 0,
    inUseSeats: 0,
  },
  addAdminDialog: false,
  addConsultantDialogOpen: false,
  confirmRemoveAdminDialog: {
    open: false,
    name: '',
    userId: null,
  },
  confirmRemoveConsultantDialog: {
    open: false,
    name: '',
    userId: null,
  },
  courseFilter: 'all',
  transferStudents: {
    open: false,
    selectedStudents: [],
    selectedTeam: null,
  },
  classes: {
    search: '',
    partner: null,
    classType: null,
  },
  addedTeam: {
    teamId: null,
    teamName: '',
  },
  addedCourses: {
    courses: [],
  },
  confirmAssessmentCodeDialog: {
    open: false,
    assessmentId: null,
    companyId: null,
  },
  editAssessmentCampaignDialog: {
    open: false,
  },
  linkAssessmentCampaignDialog: {
    open: false,
  },
  confirmAssessmentCampaignDialog: {
    open: false,
    assessmentId: null,
  },
  merge: {
    open: false,
    selectedRows: [],
  },
  campaignFilter: {
    selected: null,
    compared: null,
    role: null,
  },
  editSeatsDialog: {
    open: false,
    teamId: null,
    teamName: '',
    courses: [],
    subscriptions: [],
  },
  addSubscriptionDialog: {
    open: false,
    teamId: null,
    teamName: '',
    subscriptions: [],
  },
  subscriptionFilter: null,
  changeSubscriptionAdminDialog: {
    open: false,
    admins: [],
  },
};

export const companiesSlice = createSlice({
  name: 'companies',
  initialState,
  reducers: {
    setJoyrideStepIndex: (state, { payload: stepIndex = 0 }) => {
      state.joyride.stepIndex = stepIndex;
    },
    setJoyrideRun: (state, { payload: run = false }) => {
      state.joyride.run = false;
    },
    setSearch: (state, { payload: search }) => {
      if (typeof search === 'string') {
        state.search = search;
      } else {
        state.search = '';
      }
      state.page = 0;
    },
    setOrder: (state, { payload: order }) => {
      state.order = order;
    },
    setOrderBy: (state, { payload: orderBy }) => {
      state.orderBy = orderBy;
    },
    setPage: (state, { payload: page }) => {
      state.page = page;
    },
    setLimit: (state, { payload: limit = 100 }) => {
      state.limit = limit;
    },
    setAddTeamDialogOpen: (state, { payload = false }) => {
      state.addTeamDialogOpen = payload;
    },
    setAddCourseDialogOpen: (state, { payload: courseDialogOpen }) => {
      state.addCourseDialogOpen = courseDialogOpen;
    },
    setDeleteTeamDialog: (
      state,
      { payload = initialState.deleteTeamDialog }
    ) => {
      state.deleteTeamDialog = payload;
    },
    setEditCourseSeatsDialog: (
      state,
      { payload = initialState.editCourseSeatsDialog }
    ) => {
      state.editCourseSeatsDialog = payload;
    },
    setCourseFilter: (state, { payload: courseFilter = 'all' }) => {
      state.courseFilter = courseFilter;
    },
    setAddAdminDialogOpen: (state, { payload: open = false }) => {
      state.addAdminDialogOpen = open;
    },
    setConfirmRemoveAdminDialog: (
      state,
      {
        payload: confirmRemoveAdminDialog = {
          open: false,
          name: '',
          userId: null,
        },
      }
    ) => {
      state.confirmRemoveAdminDialog = confirmRemoveAdminDialog;
    },
    addSelectedStudent: (
      state,
      {
        payload: selectedUser = {
          id: null,
          totalProgress: 0,
        },
      }
    ) => {
      const selectedIndex = state.transferStudents.selectedStudents.findIndex(
        ({ id }) => id === selectedUser.id
      );
      if (selectedIndex === -1) {
        state.transferStudents.selectedStudents.push(selectedUser);
      }
    },
    removeSelectedStudent: (state, { payload: userId }) => {
      state.transferStudents.selectedStudents =
        state.transferStudents.selectedStudents.filter(
          ({ id }) => id !== userId
        );
    },
    resetSelectedStudents: (state) => {
      state.transferStudents.selectedStudents = [];
    },
    toggleTransferDialog: (state, { payload }) => {
      state.transferStudents.open = !!payload;
    },
    toggleEditAssessmentCampaignDialog: (state, { payload }) => {
      state.editAssessmentCampaignDialog.open = !!payload;
    },
    toggleLinkAssessmentCampaignDialog: (state, { payload }) => {
      state.linkAssessmentCampaignDialog.open = !!payload;
    },
    toggleConfirmAssessmentCampaignDialog: (
      state,
      { payload: { open = false, assessmentId = null } }
    ) => {
      state.confirmAssessmentCampaignDialog.open = open;
      state.confirmAssessmentCampaignDialog.assessmentId = assessmentId;
    },
    setSelectedTeam: (state, { payload }) => {
      state.transferStudents.selectedTeam = payload;
    },
    setSelectedAll: (state, { payload = [] }) => {
      state.transferStudents.selectedStudents = payload;
    },
    setRowsPerPage: (state, { payload }) => {
      state.limit = payload;
    },
    setClassesSearch: (state, { payload }) => {
      state.classes.search = payload;
    },
    setClassesPartner: (state, { payload = [] }) => {
      state.classes.partner = payload;
    },
    setClassesType: (state, { payload = [] }) => {
      state.classes.classType = payload;
    },
    resetClassFilters: (state) => {
      state.classes.partner = null;
      state.classes.classType = null;
      state.classes.search = '';
    },
    setUsersAchievementStatus: (state, { payload }) => {
      state.achievementStatus = payload;
    },
    setUsersAchievementType: (state, { payload }) => {
      state.achievementType = payload;
    },
    setAchievementTypes: (state, { payload = [] }) => {
      state.achievementTypes = payload;
    },
    resetStudentsFilters: (state) => {
      state.achievementStatus = null;
      state.achievementType = null;
      state.courseFilter = 'all';
    },
    setAddedTeam: (state, { payload }) => {
      state.addedTeam = payload;
    },
    setAddedCourses: (state, { payload }) => {
      state.addedCourses.courses = payload;
    },
    setActiveTab: (state, { payload }) => {
      state.activeTab = payload;
    },
    setAssessmentId: (
      state,
      { payload: { assessmentId = null, companyId = null } = {} }
    ) => {
      state.confirmAssessmentCodeDialog.assessmentId = assessmentId;
      state.confirmAssessmentCodeDialog.companyId = companyId;
      // opens the dialog if the inviteCodeId is being set
      if (assessmentId) {
        state.confirmAssessmentCodeDialog.open = true;
      }
    },
    toggleConfirmAssessmentCodeDialog: (state, { payload = false }) => {
      state.confirmAssessmentCodeDialog.open = payload;
      // reset the inviteCodeId when the dialog closes
      if (!payload) {
        state.confirmAssessmentCodeDialog.assessmentId = null;
        state.confirmAssessmentCodeDialog.companyId = null;
      }
    },
    setMerge: (
      state,
      { payload: { open = false, selectedRows = [] } = {} }
    ) => {
      state.merge.open = open;
      state.merge.selectedRows = selectedRows;
    },
    toggleAddConsultantDialog: (state, { payload = false }) => {
      state.addConsultantDialogOpen = payload;
    },
    setRemoveConsultantDialog: (state, { payload }) => {
      state.confirmRemoveConsultantDialog = {
        open: false,
        name: '',
        userId: null,
        ...payload,
      };
    },
    setDateRangeFilter: (state, { payload }) => {
      state.dateRangeFilter = payload;
    },
    setCampaignFilter: (state, { payload }) => {
      state.campaignFilter = payload;
    },
    setCompareCampaignFilter: (state, { payload }) => {
      state.campaignFilter.compared = payload;
    },
    setEditSeatsDialog: (state, { payload = initialState.editSeatsDialog }) => {
      state.editSeatsDialog = payload;
    },
    setAddSubscriptionDialog: (
      state,
      { payload = initialState.addSubscriptionDialog }
    ) => {
      state.addSubscriptionDialog = payload;
    },
    setChangeSubscriptionAdminDialog: (
      state,
      { payload = initialState.changeSubscriptionAdminDialog }
    ) => {
      state.changeSubscriptionAdminDialog = payload;
    },
    setSubscriptionFilter: (state, { payload = null }) => {
      state.subscriptionFilter = payload;
    },
  },
});

export default companiesSlice.reducer;

export const actions = companiesSlice.actions;
export const {
  setSearch,
  setOrder,
  setOrderBy,
  setPage,
  setRowsPerPage,
  setAddTeamDialogOpen,
  setAddCourseDialogOpen,
  setEditCourseSeatsDialog,
  setCourseFilter,
  setAddAdminDialogOpen,
  setDeleteTeamDialog,
  setConfirmRemoveAdminDialog,
  addSelectedStudent,
  removeSelectedStudent,
  resetSelectedStudents,
  toggleTransferDialog,
  toggleEditAssessmentCampaignDialog,
  setSelectedTeam,
  setAddedTeam,
  setAddedCourses,
  setAssessmentId,
  toggleConfirmAssessmentCodeDialog,
  setDateRangeFilter,
  setCampaignFilter,
} = actions;

export const selectSlice = (state) => state.companies;
export const selectSearch = (state) => state.companies.search;
export const selectTeamDialogOpen = (state) =>
  state.companies.addTeamDialogOpen;
export const selectAddCourseDialogOpen = (state) =>
  state.companies.addCourseDialogOpen;
export const selectDeleteTeamDialog = (state) =>
  state.companies.deleteTeamDialog;
export const selectCourseFilter = (state) => state.companies.courseFilter;
export const selectAddAdminDialogOpen = (state) =>
  state.companies.addAdminDialogOpen;
export const selectConfirmRemoveAdminDialog = (state) =>
  state.companies.confirmRemoveAdminDialog;
export const selectEditCourseSeatsDialog = (state) =>
  state.companies.editCourseSeatsDialog;
export const selectSelectedStudents = (state) =>
  state.companies.transferStudents.selectedStudents;
export const selectTransferStudents = (state) =>
  state.companies.transferStudents;
export const selectSelectedTeam = (state) => state.companies.selectedTeam;
export const selectClasses = (state) => state.companies.classes;
export const selectClassesSearch = (state) => state.companies.classes.search;
export const selectClassesPartner = (state) => state.companies.classes.partner;
export const selectAddedTeam = (state) => state.companies.addedTeam;
export const selectAddedCourses = (state) => state.companies.addedCourses;
export const selectJoyride = (state) => state.companies.joyride;
export const selectConfirmAssessmentCodeDialog = (state) =>
  state.companies.confirmAssessmentCodeDialog;
export const selectMerge = (state) => state.companies.merge;
export const selectEditAssessmentCampaignDialog = (state) =>
  state.companies.editAssessmentCampaignDialog;
export const selectLinkAssessmentCampaignDialog = (state) =>
  state.companies.linkAssessmentCampaignDialog;
export const selectConfirmAssessmentCampaignDialog = (state) =>
  state.companies.confirmAssessmentCampaignDialog;
export const selectAddConsultantDialogOpen = (state) =>
  state.companies.addConsultantDialogOpen;
export const selectRemoveConsultantDialog = (state) =>
  state.companies.confirmRemoveConsultantDialog;
export const selectDateRangeFilter = (state) => state.companies.dateRangeFilter;
export const selectCampaignFilter = (state) => state.companies.campaignFilter;
