import providesTagsHelper from './helpers/providesTags';

import api from './';
import { setProgress } from 'features/auth/slice';
import {
  toggleQuizHasResults,
  toggleQuizResultsDialogOpen,
  setGradedQuiz,
} from 'features/courses/slice';

export default function getQuizEndpoints(build) {
  return {
    getQuiz: build.query({
      query: ({ quizModuleId, sessionId = 1 }) =>
        `/QuizModule/quiz/${quizModuleId}/${sessionId}`,
      providesTags: (result) => providesTagsHelper({ type: 'Quiz', result }),
    }),
    selectAnswer: build.mutation({
      query: ({ quizModuleId, answerId, questionId, review }) => ({
        url: `/QuizModule/quiz/${quizModuleId}`,
        method: 'POST',
        body: {
          selected: answerId,
          question: questionId,
          review,
        },
      }),
      // optimistically updates the quiz to show the selected answer while the call to the backend saves the selection
      async onQueryStarted(
        { quizModuleId, questionId, sessionId, answerId },
        { dispatch, queryFulfilled }
      ) {
        const queryParams = { quizModuleId };
        if (sessionId) {
          // Replace this with your actual condition
          queryParams.sessionId = sessionId;
        }
        const patchResult = dispatch(
          api.util.updateQueryData('getQuiz', queryParams, (draft) => {
            const index = draft.questions.findIndex(
              ({ question_id }) => question_id._id === questionId
            );
            if (index >= 0) {
              draft.questions[index].selected = answerId;
            }
          })
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
      // invalidatesTags: ['Quiz'],
    }),
    submitQuiz: build.mutation({
      async queryFn(
        { quizId, quizModuleId, sessionId = 1, courseId },
        { dispatch },
        _extraOptions,
        fetchWithBQ
      ) {
        let url = `/QuizModule/quiz/submit/${quizId}/${quizModuleId}/${sessionId}`;
        if (courseId) {
          url = `${url}/${courseId}`;
        }

        const response = await fetchWithBQ({
          url,
          method: 'POST',
          body: {},
        });

        if (response.data) {
          // store the gradedQuiz and open the results / results dialog
          dispatch(setGradedQuiz(response.data?.gradedQuiz));
          dispatch(toggleQuizHasResults(true));

          if (
            response.data?.gradedQuiz?.renewal ||
            response.data?.gradedQuiz?.final_exam
          ) {
            dispatch(toggleQuizResultsDialogOpen(true));
          }

          // sets the auth.user.progress to the new progress optimistically
          // the auth user gets invalidated and refetched but we don't need to wait for that
          dispatch(setProgress({ progress: response.data.progress }));

          return { data: response.data };
        } else {
          return { error: response?.error || 'Unknown error' };
        }
      },
      invalidatesTags: ['Quiz', 'Achievement', 'UserProgress', 'AuthUser'],
    }),
    updateQuizQuestion: build.mutation({
      query: ({ quizId, currentQuestion, quizModuleId, sessionId }) => ({
        url: `/QuizModule/quiz/updateQuizQuestion/${quizId}`,
        method: 'POST',
        body: { currentQuestion },
      }),
      async onQueryStarted(
        { currentQuestion, quizModuleId, sessionId },
        { dispatch, queryFulfilled }
      ) {
        const patchResult = dispatch(
          api.util.updateQueryData(
            'getQuiz',
            {
              quizModuleId,
              ...(sessionId && { sessionId }),
            },
            (quiz) => {
              quiz.current_question = currentQuestion;
            }
          )
        );
        queryFulfilled.catch(patchResult.undo);
      },
    }),
    resetQuizAttempts: build.mutation({
      query: ({ user, moduleId, quizId }) => ({
        url: `/QuizModule/reset/${quizId}/${moduleId}`,
        method: 'POST',
        body: user,
      }),
      invalidatesTags: (result, error, arg) =>
        arg?.user
          ? [{ type: 'User', id: arg.user._id }, 'UserCourseProgress']
          : ['User'],
    }),
    generateCredential: build.mutation({
      query: (achievement) => ({
        url: `/QuizModule/quiz/generate_cert`,
        method: 'POST',
        body: achievement,
      }),
      invalidatesTags: ['Achievement'],
    }),
  };
}
