import React from 'react';
import { Button } from '@mui/material';
import { styled } from '@mui/system';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import AIIcon from 'utils/helpers/aiIcon';
import { actions } from 'features/ai/slice';
import { selectCurrentUser } from 'features/auth/slice';
import moment from 'moment';

const StyledButton = styled(Button)(({ theme }) => ({
  marginRight: theme.spacing(2),
}));

const StyledIcon = styled(AIIcon)(({ theme }) => ({
  height: 16,
}));

export default function AICoachButton() {
  const { t } = useTranslation('ai');
  const dispatch = useDispatch();
  const currentUser = useSelector(selectCurrentUser);
  const [hasAccess, setHasAccess] = React.useState(false);

  const handleOpen = () => {
    dispatch(actions.setDialogOpen(true));
  };

  React.useEffect(() => {
    const trainerAchievements = currentUser?.achievements?.filter((ach) =>
      /trainer/i.test(ach.name)
    );
    const isActiveTrainer = trainerAchievements
      ? trainerAchievements.some((ach) =>
          moment(ach.date_expires).isAfter(moment())
        )
      : false;
    if (
      currentUser?.role === 'admin' ||
      currentUser?.role === 'manager' ||
      currentUser?.username.toLowerCase().includes('scruminc.com') ||
      (currentUser?.role === 'trainer' && isActiveTrainer)
    ) {
      setHasAccess(true);
    } else if (
      currentUser?.companies?.some(({ role }) => role === 'consultant')
    ) {
      setHasAccess(true);
    } else if (currentUser?.features?.SiAiChat) {
      setHasAccess(true);
    } else if (
      currentUser?.companies?.some(({ subscriptions }) =>
        subscriptions?.some(({ status }) => status === 'active')
      )
    ) {
      setHasAccess(true);
    }
  }, [currentUser]);

  if (!currentUser || !hasAccess) return null;

  return (
    <StyledButton
      color="purple"
      variant="contained"
      size="small"
      onClick={handleOpen}
      startIcon={<StyledIcon />}
    >
      {t('ai:chat.title', { agileCopilot: 'Agile Copilot' })}
    </StyledButton>
  );
}
